/* Sitewide Variables */
:root {
  --primary-color: #88744a;
  --secondary-color: #555;
  --cta-green: #13ae4b;
  --speed: 500ms;  
  --box-shadow: 0 0 32px rgba(0,0,0,0.5);
}
/* CSS Reset */
* {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  background: none;
  border-radius: 0;
  outline: none;
  letter-spacing: 0.04em;
}
/* Set core body defaults */
body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  overflow-x: hidden;   
}
/* Sticky Footer */
#root {
  display: flex; 
  flex-direction: column;    
  min-height: 100vh;
}
header {
  position: sticky;
  top: 0;
  box-shadow: var(--box-shadow);
  background: white;
  z-index: 1;
}
nav a {
  line-height: 50px;
}
.main {
  flex-grow: 1; 
}
footer {
  flex-shrink: 0;
}
/* Helper Classes */
.primary-color {
  color: var(--primary-color);
}
.success {
  color: var(--cta-green);
}
.danger {
  color: red;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.relative {
  position: relative;
}
.float-r {
  float: right;
}
.background-cover { 
  background-position: center;
  background-size: cover;
}
.round {
  border-radius: 100%;
}
/* Element Styles */
iframe {
	display: block;
	width: 100%;
	height: 500px;	
	border: solid 1px #ccc;  
}
a,
.fa,
.icon {
	color: #999;
	transition: all var(--speed);
  text-decoration: none;
}
a:hover,
.fa:hover,
.icon:hover {
	color: var(--primary-color);
}
.checkbox {
  color: var(--primary-color) !important;
}
ul {
	list-style: none;
}
.logo li {
  line-height: 1.8;
}
h1 {
  font-size: 62px;
}
h1,
h2 {
  font-weight: 100;
}
a h2 {
  display: inline-block;
}
h3 {
  margin: 40px 0;
  color: #999;
  font-weight: 100;
}
h4 {
  color: #0cf;
}
p {	
	margin: 20px 0 40px 0;
	font-size: 14px;
	line-height: 1.6em;
  font-weight: 100;
}
p svg {
  color: var(--primary-color);
}
b {
  color: #999
}
li small {
  color: #999;
}
hr {
	padding-bottom: 1px; 
	background: linear-gradient(to left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%) left bottom #fff no-repeat;  
  border: none; 
	margin: 50px 0 80px 0;
}
table {
  width: 100%;
}
td {
  padding: 5px;
  font-weight: 100;
  font-size: 14px;
}
/* Layout Styles */
.container {
  max-width: 1280px; 
  margin: auto;
}
.wrapper {
  padding: 40px;
}
.wrapper-x {
  padding: 0 40px;
}
.parent {
  display: flex;
  justify-content: space-between;
}
.col-6 {
  flex-basis: 50%;
}
.text-center {
  text-align: center;
}
.d-flex {
  display: flex;
}
.align-tems-c {
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-c {
  justify-content: center;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fluid {
  width: 100%;
  height: auto;
}
.backdrop {
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0; 
  z-index: 1;
}
.spinner {
  position: relative;
}
.loader {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  z-index: -1;
  color: var(--primary-color) !important;
}
.icons {
  margin: 20px 0;
}
.icons img {
	margin-right: 5px;
	height: 25px;
	width: auto;
}
/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 12px; 
  height :12px;
}  
::-webkit-scrollbar-track-piece {
  background:#eee;
} 
::-webkit-scrollbar-button {
  background:#ccc;
}
::-webkit-scrollbar-thumb {
  background: var(--primary-color); 
  border-radius: 10px; 
  border: solid 3px #fff;
}

@media ( max-width: 768px ) {
  .parent {
    flex-direction: column;
  }
  header .parent {
    flex-direction: row;
  }
  div {
    width: 100% !important;
  }
  .wrapper {
    padding: 20px;
  }
  .wrapper-x{
    padding: 0 20px;
  }  
  h1 {
    font-size: 42px;
  }
  header h2 {
    font-size: 16px;
  }
  header .round {
    width: 50px;
    height: 50px;
  }
}

@media ( max-width: 468px ) {
  header h2 {
    font-size: 14px;
  }
  nav a {
    font-size: 12px;
  }
}