/* Transition Styles */
#stage {
	transition: transform 2s; 
    height: 400px; 
	perspective: 800px; 
	perspective-origin: 50% 50%;	
}
.stage {  
    border: solid 1px #ccc;
}
#shape {
	position: relative; 
	top: 50px; 
	margin: 0 auto; 
	height: 200px; 
	width: 200px; 
	transform-style: preserve-3d; 
	animation: spin 12s infinite linear;
}
#shape li {
	position: absolute; 
	height: 200px; 
	width: 200px; 
	border: 1px solid #999; 
	border-radius: 12px; 
    text-align: center; 
	font-family: Times, serif; 
	font-size: 124pt; 
	background-color: rgba(0, 0, 0, 0.1);              
	transition: transform 2s, opacity 2s;			 
	backface-visibility: hidden; 
	color: var(--primary-color);}
#shape.backfaces li { 
	backface-visibility: visible; 
}
@keyframes spin {
    from {transform: rotateY(0);}
    to   {transform: rotateY(-360deg);}
}
.ring li:nth-child(1) { transform: translateZ(180px); }
.ring li:nth-child(2) { transform: rotateY(60deg) translateZ(180px); }
.ring li:nth-child(3) { transform: rotateY(120deg) translateZ(180px); }
.ring li:nth-child(4) { transform: rotateY(180deg) translateZ(180px); }
.ring li:nth-child(5) { transform: rotateY(240deg) translateZ(180px); }
.ring li:nth-child(6) { transform: rotateY(300deg) translateZ(180px); }

.cube li:nth-child(1) { transform: scale3d(1.2, 1.2, 1.2) rotateX(90deg) translateZ(100px);}
.cube li:nth-child(2) { transform: scale3d(1.2, 1.2, 1.2) translateZ(100px);}
.cube li:nth-child(3) { transform: scale3d(1.2, 1.2, 1.2) rotateY(90deg) translateZ(100px);}
.cube li:nth-child(4) { transform: scale3d(1.2, 1.2, 1.2) rotateY(180deg) translateZ(100px);}
.cube li:nth-child(5) { transform: scale3d(1.2, 1.2, 1.2) rotateY(-90deg) translateZ(100px);}
.cube li:nth-child(6) { transform: scale3d(1.2, 1.2, 1.2) rotateX(-90deg) translateZ(100px) rotate(180deg);}
/* SVGs */
.preloaders {
    display: flex; 
    padding: 20px;
    flex-wrap: wrap;
}
.preloaders > div {
    flex: 1;
    background-color: #111;
    text-align: center;
    margin: 20px; 
    min-width: 180px;
    min-height: 200px;
}
.preloader { 
  padding: 40px;     
}  
.preloader svg {
    overflow: inherit;
}
.stage h6 {
    font-size: 14px;
    color: #999;
    text-align: center;
    position: relative;
    top: 20px;
}
.path {  
  stroke-width: 1;  
  stroke-linecap: round;                 
  fill: none;  
}  

.draw {
  stroke-dasharray: 645; 
  stroke: #fd961a; 
  animation: draw 5s ease-in-out infinite;
}
@keyframes draw {
  0% {
      stroke-dashoffset: 645; 
      opacity: 0;                    
  }
  15% {
      opacity: 1;
  }
  70% {
      opacity: 1;
  }
  100% {
      stroke-dashoffset: 0;
      opacity: 0;                    
  }
}
.drawTwo {
    stroke-dasharray: 50; 
    stroke: #fd961a; 
    animation: drawTwo 5s linear infinite; 
}
@keyframes drawTwo {
    0% {
        stroke-dashoffset: 50; 
        opacity: 0;                    
    }
    15% {
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    100% {
        stroke-dashoffset: 0;
        opacity: 0;                    
    }
}
.pinball {
    stroke-dasharray: 645,10;   
    stroke: #0cf; 
    animation: pinball 3s linear infinite;
}
.pinballTwo {
    stroke: #0cf; 
}
@keyframes pinball {
    0% {
        stroke-dashoffset: 0; 
    } 
    100% {
        stroke-dashoffset: 645;
    }
}
#tron {
    position: relative;
}
#tron .preloader {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
}
.tronOne {
    stroke-dasharray: 645, 100;   
    stroke: #0cf; 
    animation: tronOne 10s linear infinite;
}
@keyframes tronOne {
    0% {
        stroke-dashoffset: 645; 
    }     
    100% {
        stroke-dashoffset: 0;
    }
}
.tronTwo {
    stroke-dasharray: 645, 100;   
    stroke: #fd961a; 
    animation: tronTwo 7s ease-in-out infinite;
    transform: scale(1.2) rotate(15deg);
}
@keyframes tronTwo {
    0% {
        stroke-dashoffset: 345; 
    }   
    100% {
        stroke-dashoffset: 0;
    }
}
/* fish slates animation */
.product {
    white-space: nowrap;
}
.product img {
    width: 100%;
    height: auto;
}
.product > div {
    width: 33%; 
    display: inline-block; 
    transform: scale(.8);
}
.front {
    backface-visibility: hidden; 
    transform: perspective(2000px) rotateY(0deg);
}
.back {
    backface-visibility: hidden; 
    transform:perspective(2000px) rotateY(180deg); 
    position:absolute;
}
.product > .zoom {
    transform: scale(1);
}	
.anim .front {
    animation: spinfish  12s infinite linear;
}
.anim .back {
    animation: spinfish2 12s infinite linear;
}
@keyframes spinfish {
    from {
        transform:perspective(2000px) rotateY(0);
    }
    to {
        transform:perspective(2000px) rotateY(360deg);
    }
}
@keyframes spinfish2 {
    from {
        transform:perspective(2000px) rotateY(-180deg);
    }
    to {
        transform:perspective(2000px) rotateY(180deg);
    }
}

@media (max-width: 768px) {
    .product > div {
        width: 33% !important;
    }
}